import React, { useState, useEffect } from 'react';
import { LocalizedString} from "../models/landing";
import { Card, Tabs, Input, Divider, Tooltip, Typography, Space, Switch } from 'antd';
import { CardTitle } from '../../components/Card';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

interface Props {
    title: LocalizedString,
    name: string,
    enabled: boolean,
    onChange?: (isChanged: boolean, update: any) => void
}

export const LandingTitleInput: React.FunctionComponent<Props> = ({ title, name, enabled, onChange }) => {

    const [frTitle, setFrTitle] = useState(title.fr);
    const [enTitle, setEnTitle] = useState(title.en);
    const [landingName, setLandingName] = useState(name);
    const [active, setActive] = useState(enabled);
    const landingTooltip: string = "This is a convenience name only displayed in the admin landing table";

    useEffect(() => {
        const isChanged: boolean = JSON.stringify(frTitle) !== JSON.stringify(title.fr) || 
            JSON.stringify(enTitle) !== JSON.stringify(title.en) || 
            JSON.stringify(landingName) !== JSON.stringify(name) || JSON.stringify(active) !== JSON.stringify(enabled);
        if(onChange) 
            onChange(isChanged, {name: landingName, title: {fr: frTitle, en: enTitle}, enabled: active});
    }, [frTitle, enTitle, landingName, active]);

    return (
        <Card size="small" bordered={false}>
            <CardTitle>
                <Typography.Title level={4}>General and Title</Typography.Title>
            </CardTitle>

            <Typography.Title level={5}>Landing Name</Typography.Title>
            <Space direction='horizontal'>
                <Tooltip title={landingTooltip} trigger={['focus']} placement="topLeft">
                    <Input placeholder="Landing name" value={landingName} onChange={
                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                const { name, value } = e.target;
                                setLandingName(value); }}/>
                </Tooltip>
                <Switch checked={active}
                    checkedChildren={<span> Active <CheckOutlined/> </span>} 
                    unCheckedChildren={<span> Disabled <CloseOutlined/> </span>} onChange={
                        (checked: boolean) => {
                                setActive(checked); }
                    } />
            </Space>

            <Divider />

            <Typography.Title level={5}>Landing Title</Typography.Title>
            <Tabs items={[
                {
                    key: 'fr',
                    label: 'French',
                    children: (<Input placeholder="Title - fr"  value={frTitle} onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) => {
                            const { name, value } = e.target;
                            setFrTitle(value); }}/>)
                }, {
                    key: 'en',
                    label: 'English',
                    children: (<Input placeholder="Title - en"  value={enTitle} onChange={
                        (e: React.ChangeEvent<HTMLInputElement>) => {
                            const { name, value } = e.target;
                            setEnTitle(value); }}/>)
                }
            ]}>

            </Tabs>
        </Card>
    );
};