import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Tag} from 'antd';


interface Props {
    loading?: boolean;
    landings: object[] | undefined;
  }

export const LandingTable: React.FunctionComponent<Props> = ({
    loading,
    landings
}) => {
    const columns = [

        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (text: string) => {
            
            return (<strong>{text}</strong>)}
        },
        {
          title: 'State',
          dataIndex: 'enabled',
          key: 'state',
          render: (enabled: boolean) => {
            if(enabled)
              return (<Tag color='green'>Active</Tag>);
            else return(<Tag >Disabled</Tag>); 
          }
        },
        {
          title: 'Created',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date: string) => (<span>{date.substring(0, 10)}</span>),
        },  
        {
          title: 'Action',
          key: 'action',
          render: (landing: {_id? : string}) => (
            <span>
              {
                landing._id? (
                  <Link to={`/landings/${landing._id}`}>
                    Edit
                  </Link>
                ) : (
                  <span>No ID Available</span>
                ) 
              }
            </span>
          ),
        },
        
    ];

    return (
      <Table
          rowKey='createdAt'
          columns={columns as any}
          loading={loading}
          dataSource={landings}
      />
    );
}