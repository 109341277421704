import React, { useState, useEffect } from 'react';
import { LandingCta } from '../models/landing';
import { Card, Tabs, Input, Divider, Space, Cascader, CascaderProps, Typography } from 'antd';
import type { DefaultOptionType } from 'antd/es/cascader';
import { CardTitle } from '../../components/Card';


interface Props {
    ctaData: LandingCta,
    onChange?: (isChanged: boolean, update: any) => void
}


export const LandingCtaInput: React.FunctionComponent<Props> = ({ ctaData, onChange }) => {

    const [frText, setFrText] = useState<string>(ctaData.text.fr);
    const [frUrl, setFrUrl] = useState<string>(ctaData.url.fr);
    const [enText, setEnText] = useState<string>(ctaData.text.en);
    const [enUrl, setEnUrl] = useState<string>(ctaData.url.en);

    const [targetLink, setTargetLinK] = useState<string>(ctaData.linkTarget);

    const targetLinkOption = [
        {
            value: '_blank',
            label: 'Open cta link on a new tab',
        },
        {
            value: '_self',
            label: 'Open cta link on the same page',
        }
    ];
    const onCascaderChange: CascaderProps<DefaultOptionType>['onChange'] = (
        values: (string | number)[] | undefined,
        selectedOptions?: DefaultOptionType[]
    ) => {
        console.log("Cascader value:", values);
        console.log("Cascader option:", selectedOptions);
        if(values && values.length > 0 && (typeof values[0] === 'string'))
            setTargetLinK(values[0]);
    };
    

    useEffect(() => {
        const isChanged: boolean = (frText !== ctaData.text.fr || enText !== ctaData.text.en || 
            enUrl !== ctaData.url.en || frUrl !== ctaData.url.fr || targetLink !== ctaData.linkTarget);
        if(onChange) 
            onChange(isChanged, {
                linkTarget: targetLink,
                text: {
                    en: enText,
                    fr: frText
                },
                url: {
                    en: enUrl,
                    fr: frUrl
                }
        });
    }, [frText, enText, frUrl, enUrl, targetLink]);

    return (
        <Card size="small"  bordered={false}>

            <CardTitle>
                <Typography.Title level={4}>Call to action</Typography.Title>
            </CardTitle>

            <Space direction={'vertical'} size={'small'} style={{display: 'flex'}}>
                <Tabs items={[
                    {
                        key: 'fr',
                        label: 'French',
                        children: (
                        
                        <Space direction='vertical' style={{display: 'flex'}}>

                            <Input placeholder="CTA text - fr"  value={frText} onChange={
                                    (e: React.ChangeEvent<HTMLInputElement>) => {
                                        const { name, value } = e.target;
                                        setFrText(value); }}
                                        style={{ width: '100%' }}/>

                            <Input placeholder="CTA link - fr"  value={frUrl} onChange={
                                    (e: React.ChangeEvent<HTMLInputElement>) => {
                                        const { name, value } = e.target;
                                        setFrUrl(value); }}/>

                        </Space>
                        )
                    }, {
                        key: 'en',
                        label: 'English',
                        children: (
                            <Space direction='vertical' style={{display: 'flex'}}>

                            <Input placeholder="CTA text - en"  value={enText} onChange={
                                    (e: React.ChangeEvent<HTMLInputElement>) => {
                                        const { name, value } = e.target;
                                        setEnText(value); }}
                                        style={{ width: '100%' }}/>

                            <Input placeholder="CTA link - en"  value={enUrl} onChange={
                                    (e: React.ChangeEvent<HTMLInputElement>) => {
                                        const { name, value } = e.target;
                                        setEnUrl(value); }}/>

                        </Space>
                        )
                    }
                ]}>
                </Tabs>

                <Divider />

                <Cascader defaultValue={[ctaData.linkTarget]} options={targetLinkOption} onChange={onCascaderChange} style={{width: '100%'}}></Cascader>

            </Space>

        </Card>
    );
};