import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Layout } from 'antd';

import { LandingListPage } from '../../landing/pages/LandingListPage';
import { LandingDetailPage } from '../../landing/pages/LandingDetailPage';
import { ArtistDetailPage } from '../../artist/pages/ArtistDetailPage';
import { ArtworkDetailPage } from '../../artwork/pages/ArtworkDetailPage';
import { CartModule } from '../../commerce/CartModule';
import { CustomerModule } from '../../customer/CustomerModule';
import { GalleryCreatePage } from '../../gallery/pages/GalleryCreatePage';
import { GalleryDetailPage } from '../../gallery/pages/GalleryDetailPage';
import { GalleryListPage } from '../../gallery/pages/gallery-list.page';
import { ProductListPage } from '../../inventory/pages/ProductListPage';
import { ArtistCreatePage } from '../../artist/pages/artist-create.page';
import { ArtistListPage } from '../../artist/pages/artist-list.page';
import { ArtworkCreatePage } from '../../artwork/pages/ArtworkCreatePage';
import { ArtworkListPage } from '../../artwork/pages/ArtworkListPage';
import { MagazineModule } from '../../magazine/magazine.module';
import { AuthorDetailPage } from '../../magazine/pages/AuthorDetailPage';
import { AuthorListPage } from '../../magazine/pages/AuthorListPage';
import { OrderModule } from '../../order/OrderModule';
import { DashboardPage } from '../../pages/dashboard.page';
import { ArticleCreatePage } from '../../magazine/pages/ArticleCreatePage';
import { PostListPage } from '../../pages/post-list.page';
import { SettingsPage } from '../../settings/SettingsPage';
import { AppBar } from '../widgets/AppBar';
import { Drawer } from '../widgets/Drawer';
import { CognitoUser } from '../../lib/cognito/CognitoUser';
import { CollectionListPage } from '../../collections/pages/CollectionListPage';
import { CollectionDetailPage } from '../../collections/pages/CollectionDetailPage';

export const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState(false);
  const toggleMenu = () => setMenu(!menu);

  useEffect(() => {
    const cognitoUser = new CognitoUser();

    try {
      cognitoUser.getSession();
      setLoading(false);
    } catch (e) {
      navigate('/login');
    }
  }, []);

  if (loading) {
    return <div />;
  }

  return (
    <Layout style={{ minHeight: '100vh', background: '#f5f9fb', backgroundColor: '#f5f9fb' }}>
      <Drawer collapsed={menu} />

      <Layout>
        <AppBar toggleMenu={toggleMenu} collapsed={menu} />

        <Routes>
          <Route path={'/landings'} element={<LandingListPage />} />
          <Route path={'/landings/:id'} element={ <LandingDetailPage/>} />
          <Route path={'/artworks/detail/:id'} element={<ArtworkDetailPage />} />
          <Route path={'/artworks/create'} element={<ArtworkCreatePage />} />
          <Route path={'/artworks'} element={<ArtworkListPage />} />
          <Route path={'/artists/create'} element={<ArtistCreatePage />} />
          <Route path={'/artists/detail/:id'} element={<ArtistDetailPage />} />
          <Route path={'/artists'} element={<ArtistListPage />} />
          <Route path={'/authors/detail/:id'} element={<AuthorDetailPage />} />
          <Route path={'/authors'} element={<AuthorListPage />} />
          <Route path={'/posts/create'} element={<ArticleCreatePage />} />
          <Route path={'/posts'} element={<PostListPage />} />
          <Route path={'/collections'} element={<CollectionListPage />} />
          <Route path={'/collections/detail/:id'} element={<CollectionDetailPage />} />
          <Route path={'/galleries/create'} element={<GalleryCreatePage />} />
          <Route path={'/galleries/detail/:id'} element={<GalleryDetailPage />} />
          <Route path={'/galleries/*'} element={<GalleryListPage />} />
          <Route path={'/inventory/*'} element={<ProductListPage />} />
          <Route path={'/customers/*'} element={<CustomerModule />} />
          <Route path={'/magazine/*'} element={<MagazineModule />} />
          <Route path={'/orders/*'} element={<OrderModule />} />
          <Route path={'/carts/*'} element={<CartModule />} />
          <Route path={'/settings/*'} element={<SettingsPage />} />
          <Route path={'/'} element={<DashboardPage />} />
        </Routes>
      </Layout>
    </Layout>
  );
};
