import gql from 'graphql-tag';

export const artworkCreate = gql`  
  mutation ArtworkCreate($input: ArtworkCreateInput!) {
      artworkCreate(
          input: $input
      ) {
          name
          description
          category
          style
          subject
          technique
      } 
  }
`;

export const artworkUpdate = gql`
    mutation ArtworkUpdate($input: ArtworkUpdateInput!) {
        artworkUpdate(
            input: $input
        ) {
            name
            description
            category
            style
            subject
            technique
            orientation
            year
            framed
            score

            dimension {
                width
                height
                depth
            }

            weight
        }
    }
`;

export const artworkDelete = gql`
    mutation ArtworkDelete($input: DeleteArtworkInput!) {
        artworkDelete(
            input: $input
        ) {
            _id
        }
    }
`;

export const artworkAssetAdd = gql`
    mutation ArtworkAssetAdd($input: ArtworkAddAssetInput!) {
      artworkAssetAdd(
          input: $input
      ) {
          url
          asset {
              _id
              url
              type
          }
      } 
  }
`;

export const artworkAssetRemove = gql`
    mutation ArtworkAssetRemove($input: ArtworkAssetRemoveInput!) {
      artworkAssetRemove(
          input: $input
      ) {
        _id
      } 
  }
`;

export const artworkFeaturedStatus = gql`
    mutation ArtworkFeaturedStatus($input: ArtworkFeaturedStatusInput!) {
        artworkFeaturedStatus (
            input: $input,
        ) {
            _id
        }
    }
`;

export const artworkHiddenStatus = gql`
    mutation ArtworkHiddenStatus($input: ArtworkHiddenStatusInput!) {
        artworkHiddenStatus(
            input: $input,
        ){
            _id
        }
    }
`;

export const artworkPriceUpdate = gql`
    mutation ArtworkPriceUpdate($input: ArtworkPriceUpdateInput!) {
        artworkPriceUpdate (
            input: $input,
        ) {
            price {
                value
                currency
            }

            priceList {
                value
                currency
            }
        }
    }
`;

export const artworkAccept = gql`
    mutation ArtworkAccept($artworkId: String!) {
        artworkAccept (
            artworkId: $artworkId,
        ) {
            _id
            status
        }
    }
`;


export const artworkReject = gql`
    mutation ArtworkReject($artworkId: String!) {
        artworkReject (
            artworkId: $artworkId,
        ) {
            _id
            status
        }
    }
`;

export const artworkTranslationUpdate = gql`
    mutation ArtworkTranslationUpdate($input: ArtworkTranslationUpdateInput!) {
        artworkTranslationUpdate (
            input: $input,
        ) {
            _id
        }
    }
`;
