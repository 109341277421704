import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import {HideSwitch} from '../../components/HideSwitch';
import {ArtworkHiddenStatus, ArtworkHiddenStatusVariables } from '../__generated__/ArtworkHiddenStatus';
import {artworkHiddenStatus} from '../artwork.mutations';
import {ArtworkFetch} from '../__generated__/ArtworkFetch';
import {artworkFetch} from '../artwork.queries';

interface Props {
  artworkId: string;
  hidden?: boolean;
}

export const ArtworkHideSwitch: React.FunctionComponent<Props> = ({ artworkId, hidden}) => {

  const [mutate] = useMutation<ArtworkHiddenStatus, ArtworkHiddenStatusVariables>(artworkHiddenStatus);
  const [boolState, setBoolState] = useState(true);

  const handleChange = (status: boolean) => {
    setBoolState(status);
    return mutate({
      variables: {
        input: {
          artworkId,
          hidden: status,
        },
      },
      update: (cache) => {
        const data = cache.readQuery<ArtworkFetch>({
          query: artworkFetch,
          variables: {
            id: artworkId
          }
        });

        if (!data) {
          return;
        }

        const { artwork } = data;

        cache.writeQuery({
          query: artworkFetch,
          variables: {
            id: artworkId
          },
          data: {
            artwork: {
              ...artwork,
              hidden: status,
            }
          }
        })
      },
    });
  };

  useEffect(() => {
    //Artworks may not have the hidden field, then an internal boolean state is used to decouple
    //If hidden is undefined, then it is set to false
    if(hidden === undefined){
      setBoolState(false);
    } else {
      setBoolState(hidden);
    }

  }, [hidden]);

  return (
  <label> 
    <span>Hidden: </span>
    <HideSwitch
      hidden={boolState}
      handleChange={handleChange}
    />
  </label>
  )
};