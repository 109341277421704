import React, { useState, useEffect } from 'react';
import { LandingText as LandingTextType} from '../models/landing';
import { Card, Tabs, Input, Button, Space, Typography} from 'antd';
import { CardTitle } from '../../components/Card';


interface Props {
    text: LandingTextType,
    onChange?: (isChanged: boolean, update: any) => void
}

const { TextArea } = Input;

export const LandingTextInput: React.FunctionComponent<Props> = ({ text, onChange }) => {

    const [frText, setFrText] = useState<string[]>(text.fr);
    const [enText, setEnText] = useState<string[]>(text.en);

    useEffect(() => {
        const isChanged: boolean = frText !== text.fr || enText !== text.en;
        if(onChange) 
            onChange(isChanged, {fr: frText, en: enText});
    }, [frText, enText]);

    return (
        <Card size="small" bordered={false}>
            <CardTitle>
                <Typography.Title level={4}>Landing Text</Typography.Title>
            </CardTitle>

            <small> <strong>Note:</strong> Html tags for text formatting are applicable. See <a href="https://www.w3schools.com/html/html_formatting.asp" target="_blank">here</a>.</small>

            <Tabs items={[
                {
                    key: 'fr',
                    label: 'French',
                    children: (
                        <>
                        {
                        frText.map((el, index) => (
                            <TextArea placeholder="Paragraph - fr"
                                key={index}  
                                value={el}
                                onChange={ e => {
                                    const updatedText = [...frText]; // Create a shallow copy of the array
                                    updatedText[index] = e.target.value; // Update the specific element
                                    setFrText(updatedText)}}
                                rows={3}
                                style={{marginBottom: '4px'}}/>   
                        ))
                        }
                        <Space direction='horizontal'>
                            <Button type="default" shape="circle" 
                                title='add a fr paragraph' 
                                onClick={() => {setFrText([...frText, ""])}}>
                                +
                            </Button>

                            <Button type="default" shape="circle" 
                                title='remove a fr paragraph' 
                                onClick={() => {setFrText([...frText.slice(0, -1)])}}>
                                -
                            </Button>
                        </Space>
                        </>
                    )
                }, {
                    key: 'en',
                    label: 'English',
                    children: (
                        <>
                        {
                        enText.map((el, index) => (
                            <TextArea placeholder="Paragraph - en"
                                key={index}  
                                value={el}
                                onChange={ e => {
                                    const updatedText = [...enText]; // Create a shallow copy of the array
                                    updatedText[index] = e.target.value; // Update the specific element
                                    setEnText(updatedText)}}
                                rows={3}
                                style={{marginBottom: '4px'}}/>   
                        ))
                        }
                        <Space direction='horizontal'>
                            <Button type="default" shape="circle" 
                                title='add an en paragraph' 
                                onClick={() => {setEnText([...enText, ""])}}>
                                +
                            </Button>

                            <Button type="default" shape="circle" 
                                title='remove an en paragraph' 
                                onClick={() => {setEnText([...enText.slice(0, -1)])}}>
                                -
                            </Button>
                        </Space>
                        </>
                    )
                }
            ]}>
            </Tabs>

        </Card>
    );
};