import { useState, useEffect } from 'react';
import useSWR, {Fetcher} from 'swr';
import { Landing } from '../models/landing';
import { api } from '../../api/api';
import { useNavigate, useParams} from 'react-router-dom';
import { Button, Layout, Alert, Spin, Row, Col, PageHeader, Modal} from 'antd';
import { LandingTitleInput } from '../components/LandingTitleInput';
import { LandingTextInput } from '../components/LandingTextInput';
import { LandingImageInput } from '../components/LandingImageInput';
import { LandingCtaInput } from '../components/LandingCtaInput';

//Load data
const fetcher: Fetcher<Landing, string> = (url) => {
    return api.get(url).then(({data}) => {
        console.log(data);
        return data;
    });
}

export const LandingDetailPage = (props:any) => {

    //Data state
    const [updatedData, setUpdatedData] = useState<Landing | null>(null);
    //Saving state
    const [isSaving, setIsSaving] = useState(false);
    const [savingError, setSavingError] = useState("");
    //Changes states
    const [hasChange, setHasChange] = useState(false);
    const [titleChanged, setTitleChanged] = useState(false);
    const [textChanged, setTextChanged] = useState(false);
    const [imageChanged, setImageChanged] = useState(false);
    const [ctaChanged, setCtaChanged] = useState(false);
    // Delete modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();
    //Get landing Id
    const { id } = useParams<{ id: string }>();

    const { data, mutate, isLoading } = useSWR('/landing/' + id, fetcher, {
        onSuccess: (fetchedData) => setUpdatedData(fetchedData), // Initialize updatedData when fetch is successful
    });

    // Modal methods
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        deleteLanding(id);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    //Check when there is a change to save
    useEffect(() => {
            if(titleChanged || textChanged || imageChanged || ctaChanged)
                setHasChange(true);
            else
                setHasChange(false);
        }, 
        [titleChanged, textChanged, imageChanged, ctaChanged]);

    //Update functions
    const updateTitle = (changed: boolean, update: any) => {
        setTitleChanged(changed);
        if(changed && updatedData){
            setUpdatedData({
                ...updatedData,
                enabled: update.enabled,
                name: update.name,
                title: {
                  en: update.title.en,
                  fr: update.title.fr,
                },
              });
        }
    };
    const updateText = (changed: boolean, update: any) => {
        setTextChanged(changed);
        if(changed && updatedData){
            setUpdatedData({
                ...updatedData,
                text: {
                    en: update.en,
                    fr: update.fr
                }
              });
        }
    };
    const updateImage = (changed: boolean, update: any) => {
        setImageChanged(changed);
        if(changed && updatedData){
            setUpdatedData({...updatedData, 
                image: {
                    src: update.image.src,
                    alt: { 
                        fr: update.image.alt.fr,
                        en: update.image.alt.en
                    }
                },
                video: {
                    youtube_id: update.video.youtube_id,
                    image_src: update.video.image_src
                }
            });
        }
    };
    const updateCta = (changed: boolean, update: any) => {
        setCtaChanged(changed);
        if(changed && updatedData){
            setUpdatedData({...updatedData, 
                cta: {
                    text: {
                        fr: update.text.fr,
                        en: update.text.en
                    },
                    url: {
                        fr: update.url.fr,
                        en: update.url.en
                    },
                    linkTarget: update.linkTarget
                }
            })
        }
    };

    const saveLanding = () => {
        setIsSaving(true);
        api.patch('/landing/' + id, updatedData)
            .then((response) => {
                setIsSaving(false);
                setSavingError("");

                //Optimistically update the landing data with current values
                mutate();

                // Reset change states
                setTitleChanged(false);
                setTextChanged(false);
                setImageChanged(false);
                setCtaChanged(false);

            }).catch( (error) => {
                setSavingError(error);
                setIsSaving(false);
            });
    };

    const deleteLanding = (id: string | undefined) => {
        api.delete('/landing/' + id).then(
            (response) => {
                navigate(-1);
            }
        ).catch( (error) => {
            setSavingError(error);
            setIsSaving(false);
        });
    };

    return(
        <Layout.Content>
            <PageHeader 
                onBack={() => navigate(-1)}
                title={'Landing Intro Article: ' + updatedData?.name}
                extra={[
                    <Button onClick={() => showModal()}
                        danger
                        title={'Delete landing'} >
                        Delete
                    </Button>,
                    <Button onClick={() => saveLanding()} 
                        title={'Save landing'} 
                        type={'primary'} 
                        loading={isSaving} 
                        disabled={!hasChange}>
                        Save
                    </Button>
                ]}>
            </PageHeader>
            {
                savingError !== "" &&
                <Alert type={'warning'} message="An error occurred while saving."> {savingError} </Alert>
            }

            {
                isLoading && 
                <Spin size="large" style={{justifyContent: 'center', display: 'flex'}}/>
            }

            <Row gutter={16}>
                <Col span={24} style={{padding: '8px'}}>
                    {data?.title ?
                        <LandingTitleInput name={data.name} title={data.title} enabled={data.enabled} onChange={(changed, update) => {updateTitle(changed, update);}}></LandingTitleInput> : 
                        <Alert type='warning' message="Data issue: Title data not properly defined"> </Alert>}
                </Col>
                <Col span={10} style={{padding: '8px'}}>
                    {data?.text ?
                        <LandingTextInput text={data.text} onChange={(changed, update) => {updateText(changed, update)}}></LandingTextInput> : 
                        <Alert type='warning' message="Data issue: Text data not properly defined"> </Alert>}
                </Col>
                <Col span={14} style={{padding: '8px'}}>
                    {data?.image && data?.video ?
                        <LandingImageInput imageData={data.image} videoData={data.video} onChange={(changed, update) => {updateImage(changed, update)}}></LandingImageInput> : 
                        <Alert type='warning' message="Data issue: Image/Video data not properly defined"> </Alert>}
                </Col>
                <Col span={24}>
                    {data?.cta ?
                        <LandingCtaInput ctaData={data.cta} onChange={(changed, update) => {updateCta(changed, update)}}></LandingCtaInput> :
                        <Alert type='warning' message="Data issue: CTA data not properly defined"> </Alert>}
                </Col>
            </Row>

            <Modal title="Delete Landing record" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>Do you want to permanently delete this landing record?</p>
            </Modal>

        </Layout.Content>
    );

}