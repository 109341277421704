import React, { useState, useEffect } from 'react';
import { LandingImage, LandingVideo } from "../models/landing";
import { Card, Tabs, Input, Image, Space, Typography, Divider} from 'antd';
import { CardTitle } from '../../components/Card';

interface Props {
    imageData: LandingImage,
    videoData: LandingVideo,
    onChange?: (isChanged: boolean, update: any) => void
}

/** 
 * Input control for Image or Video in the lading page intro article 
 **/
export const LandingImageInput: React.FunctionComponent<Props> = ({ imageData, videoData, onChange }) => {

    const [imageUrl, setImageUrl] = useState(imageData.src);
    const [altFr, setAltFr] = useState(imageData.alt.fr);
    const [altEn, setAltEn] = useState(imageData.alt.en);
    const [videoUrl, setVideoUrl] = useState(videoData.youtube_id)
    const [videoImageSrc, setVideoImageSrc] = useState(videoData.image_src);
    
    useEffect(() => {
        const isChanged: boolean = (imageUrl !== imageData.src || altFr !== imageData.alt.fr || 
            altEn !== imageData.alt.en || videoUrl !== videoData.image_src || videoImageSrc !== videoData.image_src) ;
        if(onChange) 
            onChange(isChanged, {
                image: {   
                    src: imageUrl, 
                    alt: {fr: altFr, en: altEn}
                },
                video: {
                    youtube_id: videoUrl,
                    image_src: videoImageSrc
                }
            }
        );
    }, [imageUrl, altFr, altEn, videoUrl, videoImageSrc]);

    return (
        <Card size="small" bordered={false}>
            <CardTitle>
                <Typography.Title level={4}>Landing Video or Image</Typography.Title>
            </CardTitle>
            <Space direction={"vertical"} style={{display: 'flex'}}>
                
                <small> <strong>Note:</strong> Only one between the video and the image is displayed in the article. If both the video and the image are set, the video is displayed.</small>

                <Typography.Title level={5}> Video settings </Typography.Title>
                <Space direction='vertical' style={{display: 'flex'}}>
                    <Input placeholder="Youtube id"  
                        value={ videoUrl }
                        onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    const { name, value } = e.target;
                                    setVideoUrl(value); }}/>
                    
                    <Input placeholder="Video Image url"  
                        value={ videoImageSrc }
                        onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    const { name, value } = e.target;
                                    setVideoImageSrc(value); }}/>
                    
                </Space>

                <Divider />

                <Typography.Title level={5}> Image settings </Typography.Title>
                <Space direction='vertical' style={{display: 'flex'}}>
                    <Input placeholder="Image url"  
                        value={imageUrl} 
                        onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    const { name, value } = e.target;
                                    setImageUrl(value); }}/>
                    <small>Image preview:</small>
                    <Image src={imageUrl} style={{
                        width: '100%', // Make the image adapt to the card's width
                        height: 'auto', // Maintain aspect ratio
                    }}></Image>
                </Space>

                <Tabs items={[{
                        key: 'fr',
                        label: 'French',
                        children: (
                            <Input placeholder="Image alternate text - fr"  value={altFr} onChange={
                            (e: React.ChangeEvent<HTMLInputElement>) => {
                                const { name, value } = e.target;
                                setAltFr(value); }}/>)
                    }, {
                        key: 'en',
                        label: 'English',
                        children: (
                            <Input placeholder="Image alternate text - en"  value={altEn} onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    const { name, value } = e.target;
                                    setAltEn(value); }}/>
                        )
                    }]}>
                </Tabs>
            </Space>
        </Card>
    );
    
}