import React from 'react';
import { useMutation } from 'react-apollo';
import {FeaturedSwitch} from '../../components/FeaturedSwitch';
import {ArtworkFeaturedStatus, ArtworkFeaturedStatusVariables} from '../__generated__/ArtworkFeaturedStatus';
import {artworkFeaturedStatus} from '../artwork.mutations';
import {ArtworkFetch} from '../__generated__/ArtworkFetch';
import {artworkFetch} from '../artwork.queries';

interface Props {
  artworkId: string;
  featured: boolean;
}

export const ArtworkFeaturedSwitch: React.FunctionComponent<Props> = ({ artworkId, featured}) => {
  const [mutate] = useMutation<ArtworkFeaturedStatus, ArtworkFeaturedStatusVariables>(artworkFeaturedStatus);

  const handleChange = (status: boolean) => {
    return mutate({
      variables: {
        input: {
          artworkId,
          status,
        },
      },
      update: (cache) => {
        const data = cache.readQuery<ArtworkFetch>({
          query: artworkFetch,
          variables: {
            id: artworkId
          }
        });

        if (!data) {
          return;
        }

        const { artwork } = data;

        cache.writeQuery({
          query: artworkFetch,
          variables: {
            id: artworkId
          },
          data: {
            artwork: {
              ...artwork,
              featured: status,
            }
          }
        })
      },
    });
  };

  return (
  <label>
    <span>Featured: </span>
    <FeaturedSwitch
      featured={featured}
      handleChange={handleChange}
    />
  </label>
    
  )
};