import React, { useState } from 'react';
import { message, Switch } from 'antd';

interface Props {
  hidden?: boolean;
  handleChange: (status: boolean) => Promise<any>;
}

export const HideSwitch: React.FunctionComponent<Props> = ({ hidden, handleChange }) => {
  const [loading, setLoading] = useState(false);

  const handleHidden = (status: boolean) => {
    setLoading(true);

    handleChange(status)
      .catch(() => {
        message.error('Unable to update hidden status!');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <label>
      <Switch onClick={handleHidden} title="Hide" checked={hidden} loading={loading} />
    </label>
  );
};
