import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import { CognitoUser } from '../../lib/cognito/CognitoUser';
import {
  HomeOutlined,
  BankOutlined,
  PictureOutlined,
  SmileOutlined,
  IdcardOutlined,
  SettingOutlined,
  UserOutlined,
  BarsOutlined,
  LogoutOutlined,
  LoginOutlined
} from '@ant-design/icons';

const { Sider } = Layout;

type Props = {
  collapsed: boolean;
};

export const Drawer: React.FunctionComponent<Props> = ({ collapsed }) => {
  const navigate = useNavigate();

  return (
    <Sider collapsible collapsed={collapsed} breakpoint="lg" collapsedWidth="0" trigger={null}>
      <Header />

      <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
        <Menu.Item key="1" onClick={() => navigate('/')}>
          <BankOutlined />

          <Label>Dashboard</Label>
        </Menu.Item>

        <Menu.Item key="2" onClick={() => navigate('/landings')}>
          <HomeOutlined />
          <Label>Landing</Label>
        </Menu.Item>

        <Menu.SubMenu
          key="sub1"
          title={
            <Label>
              <UserOutlined />
              Sales
            </Label>
          }>
          <Menu.Item key="sales-1" onClick={() => navigate('/inventory')}>
            Inventory
          </Menu.Item>
          <Menu.Item key="sales-2" onClick={() => navigate('/orders')}>
            Orders
          </Menu.Item>
          <Menu.Item key="sales-3" onClick={() => navigate('/carts')}>
            Carts
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item key="3" onClick={() => navigate('/artworks')}>
          <PictureOutlined />

          <Label>Artworks</Label>
        </Menu.Item>

        <Menu.Item key="4" onClick={() => navigate('/artists')}>
          <SmileOutlined />

          <Label>Artists</Label>
        </Menu.Item>

        <Menu.SubMenu
          key="magazine"
          title={
            <Label>
              <UserOutlined />
              Magazine
            </Label>
          }>
          <Menu.Item key="magazine-1" onClick={() => navigate('/magazine/articles')}>
            Articles
          </Menu.Item>
          <Menu.Item key="magazine-2" onClick={() => navigate('/authors')}>
            Authors
          </Menu.Item>
          <Menu.Item key="magazine-3" onClick={() => navigate('/tags')}>
            Tags
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item key="collection" onClick={() => navigate('/collections')}>
          <BarsOutlined />
          <Label>Collections</Label>
        </Menu.Item>

        <Menu.Item key="5" onClick={() => navigate('/galleries')}>
          <BankOutlined />

          <Label>Galleries</Label>
        </Menu.Item>

        <Menu.Item key="6" onClick={() => navigate('/customers')}>
          <IdcardOutlined />

          <Label>Customers</Label>
        </Menu.Item>

        <Menu.Item key="7" onClick={() => navigate('/settings')}>
          <SettingOutlined />

          <Label>Settings</Label>
        </Menu.Item>

        <Menu.Divider></Menu.Divider>

        <Menu.Item key="8" onClick={() => {
            const cognitoUser = new CognitoUser();
            cognitoUser.signOut();
            navigate('/login');
          
          }}>
          <LogoutOutlined />

          <Label>Logout</Label>
        </Menu.Item>

      </Menu>
    </Sider>
  );
};

const Header = styled.div`
  height: 7rem;
`;

const Label = styled.span`
  font-family: 'Sarabun', sans-serif;
  color: #b4c9de;
  font-size: 1.6rem;
  text-decoration: none;
  user-select: none;
  font-weight: 400;
`;
