import { api } from '../../api/api';
import useSWR, {Fetcher} from 'swr';
import { useState } from 'react';
import { Button, Layout, Alert, PageHeader } from 'antd';
import { PageTitle } from '../../components/layouts/page/PageTitle';
import { LandingTable } from '../components/LandingTable';


export const LandingListPage = (props:any) => {

    //Landing creation state
    const [isCreating, setIsCreating] = useState(false);
    //Error message
    const [errorMessage, setErrorMessage] = useState("");
    

    const fetcher: Fetcher<object[], string> = (url) => {
        return api.get(url).then(({data}) => {
            console.log(data);
            return data;
        });
    }
    const { data, mutate, isLoading } = useSWR('/landing/all', fetcher);

    const createLanding = async () => {

        try {
            setIsCreating(true);
            await api.post('/landing').then(() => setIsCreating(false)); // Call the API to create a new item
            mutate(); // Trigger a re-fetch for the SWR data
        } catch (err) {
            if (err instanceof Error) {
                setErrorMessage(err.message); // Standard error message
            } else {
                setErrorMessage(String(err)); // Convert non-standard errors to a string
            }            
        }
    };

    const onAlertClose = () => {
        setErrorMessage("");
    };

    return (
        <Layout.Content>
            <PageHeader
                title="Landing Intro Articles"
                extra={[
                    <Button onClick={() => createLanding()} title={'new landing'} type={'primary'} loading={isCreating}>
                        New landing
                    </Button>
                ]}
            />

            {
                errorMessage !== "" &&
                <Alert
                    message={errorMessage}
                    type="warning"
                    closable
                    onClose={onAlertClose}
                />
            }
            
            <LandingTable 
                loading={isLoading}
                landings={data}
            />

        </Layout.Content>
    );

}